<template>
  <b-modal
    id="new-campaign-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('create_a_campaign_group') }}</span> <span v-b-tooltip.hover :title="tooltips.packages"><i class="fa fa-info-circle font-size-medium"></i></span>
      </h2>
    </div>
    <div class="text-center mt-3">
      <p>{{ $t('congratulations_on_your_new_campaign') }}</p>
      <p>{{ $t('next_step_is_to_create_a_campaign_group') }}</p>
      <p>{{ $t('to_be_taken_to_the_creation_page') }}</p>
      <router-link
        class="btn btn-primary mt-4"
        :to="{ name: 'packages.create', params: { cId: campaign ? campaign.id : '' } }"
      >
        {{ $t('create_a_group') }}
      </router-link>
    </div>
  </b-modal>
</template>

<script>

import variables from '@/mixins/variables'

export default {
  name: 'NewCampaignModal',
  mixins: [variables],
  props: ['campaign']
}
</script>
