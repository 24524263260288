<template>
  <b-modal
    id="confirm-campaign-edit-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('confirm_campaign_edit_title') }}</span>
      </h2>
    </div>

    <div class="text-center mt-3">
      <div class="mb-4">
        <i class="fa fa-warning font-size-large"></i>
      </div>
      <p v-html="$t('confirm_campaign_edit.0', {name: campaign.name})"></p>
      <p v-html="$t('confirm_campaign_edit.1')"></p>
      <div class="mt-3">
        <router-link
          :to="{ name: 'campaigns.edit', params: { id: campaign.id } }"
          class="btn btn-sm btn-primary me-2"
          v-if="campaign && campaign.status !== 'FINISHED'"
        >
          {{ $t('edit_campaign') }}
        </router-link>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'ConfirmCampaignEditModal',
  props: ['campaign'],
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
