var variables = {
  data () {
    return {
      paymentStatus: [
        {
          key: 'PAID',
          value: 'Pago'
        },
        {
          key: 'PENDING',
          value: 'Pendente'
        },
        {
          key: 'PROCESSING',
          value: 'Processando'
        },
        {
          key: 'AUTHORIZED',
          value: 'Autorizado'
        },
        {
          key: 'REFUNDED',
          value: 'Estornado'
        },
        {
          key: 'WAITING_PAYMENT',
          value: 'Aguardando pagamento'
        },
        {
          key: 'PENDING_REFUND',
          value: 'Aguardando estorno'
        },
        {
          key: 'REFUSED',
          value: 'Recusado'
        },
        {
          key: 'CHARGEDBACK',
          value: 'Chargeback'
        },
        {
          key: 'EXPIRED',
          value: 'Expirado'
        },
        {
          key: 'CANCELED',
          value: 'Cancelado'
        }
      ],
      tooltips: {
        currency: this.$t('tooltips.currency'),
        billing_profile: this.$t('tooltips.billing_profile'),
        lines: this.$t('tooltips.lines'),
        pixels: this.$t('tooltips.pixels'),
        creatives: this.$t('tooltips.creatives'),
        site_lists: this.$t('tooltips.site_lists'),
        locations: this.$t('tooltips.locations'),
        audiences: this.$t('tooltips.audiences'),
        platform_audiences: this.$t('tooltips.platform_audiences'),
        packages: this.$t('tooltips.packages')
      },
      countries: [
        'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM',
        'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ',
        'BM', 'BT', 'BO', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI',
        'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO',
        'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ',
        'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ',
        'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR',
        'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA',
        'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT',
        'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA',
        'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW',
        'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD',
        'MC', 'MN', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'AN', 'NC',
        'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS',
        'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO',
        'RU', 'RW', 'SH', 'KN', 'LC', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN',
        'CS', 'SC', 'SL', 'SG', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'ES', 'LK',
        'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL',
        'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE',
        'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH',
        'YE', 'ZM', 'ZW'
      ],
      availableLanguages: [
        'en', 'pt_BR', 'es'
      ]
    }
  }
}

export default variables
