var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex flex-column flex-lg-row px-4"},[_c('div',{staticClass:"col-lg-2 mb-2 mb-lg-0"},[_c('h2',{staticClass:"highlighted-color1 font-size-regular"},[_c('span',[_vm._v(_vm._s(_vm.$t('impressions')))])]),_c('div',{staticClass:"font-size-medium"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.data.impressions))+" ")])]),_c('div',{staticClass:"col-lg-2 mb-2 mb-lg-0"},[_c('h2',{staticClass:"highlighted-color1 font-size-regular"},[_c('span',[_vm._v(_vm._s(_vm.$t('views')))])]),_c('div',{staticClass:"font-size-medium"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.data.complete_views))+" ")])]),_c('div',{staticClass:"col-lg-2 mb-2 mb-lg-0"},[_c('h2',{staticClass:"highlighted-color1 font-size-regular"},[_c('span',[_vm._v(_vm._s(_vm.$t('vtr')))])]),_c('div',{staticClass:"font-size-medium"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.data.vtr))+"% ")])]),(_vm.view !== 'lines')?_c('div',{staticClass:"col-lg-3 mb-2 mb-lg-0"},[_c('h2',{staticClass:"highlighted-color1 font-size-regular"},[_c('span',[_vm._v(_vm._s(_vm.$t('investment')))])]),_c('div',{staticClass:"font-size-medium"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.data.amount / 100,_vm.currency))+" ")])]):_vm._e(),_c('div',{staticClass:"col-lg-3"},[_c('h2',{staticClass:"highlighted-color1 font-size-regular"},[_c('span',[_vm._v(_vm._s(_vm.$t('consumed')))])]),_c('div',{staticClass:"font-size-medium"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.data.spending / 100,_vm.currency))+" ")])])]),_vm._v(" "),_c('div',{staticClass:"d-none d-lg-block mt-4"},[_c('LineChartGenerator',{attrs:{"chart-options":{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left'
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',

                // grid line settings
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                }
              }
            }
          },"chart-data":_vm.chartData,"chart-id":"chart","dataset-id-key":"label","height":350}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }