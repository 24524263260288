<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex justify-content-between align-items-center">
      <div class="col-lg-7">
        <h1 class="highlighted-color1 font-size-large wrap-text">
          <span>{{ campaign.name }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <template v-if="!campaign.is_read_only">
          <router-link
            :to="{ name: 'campaigns.edit', params: { id: campaign.id } }"
            class="btn btn-sm btn-primary me-2"
            v-if="campaign && campaign.status !== 'FINISHED'"
          >
            {{ $t('edit_campaign') }}
          </router-link>
          <template v-if="campaign.status === 'DRAFT'">
            <Button
              class="btn-sm btn-color1 me-2"
              v-b-modal.destroy-campaign-modal
            >
              {{ $t('delete') }}
            </Button>
            <DestroyCampaignModal
              :campaign="campaign"
              @campaign-destroyed="handleDestroyed"
            />
          </template>
          <template v-if="campaign.status !== 'FINISHED'">
            <template v-if="campaign.status === 'ACTIVE'">
              <Button
                class="btn-sm btn-color1 me-2"
                v-b-modal.pause-campaign-modal
              >
                {{ $t('pause') }}
              </Button>
              <PauseCampaignModal
                :campaign="campaign"
                @campaign-paused="getCampaign"
              />
            </template>
            <template v-else-if="campaign.status === 'PAUSED'">
              <Button
                class="btn-sm btn-color1 me-2"
                v-b-modal.activate-campaign-modal
              >
                {{ $t('enable') }}
              </Button>
              <ActivateCampaignModal
                :campaign="campaign"
                @campaign-activated="getCampaign"
              />
            </template>
          </template>
        </template>
        <router-link :to="{ name: 'campaigns.index' }" class="btn btn-sm btn-primary">{{ $t('back') }}</router-link>
      </div>
    </div> <!-- END HEADER -->
    <!-- CAMPAIGN -->
    <div class="mt-4">
      <!-- MESSAGES -->
      <div
        class="mb-4 error-message-card text-white font-size-small p-3"
        v-if="hasMessage"
      >
        <p
          class="mb-0"
          v-if="campaign.status === 'ERROR'"
        >
          {{ $t('error_creating_campaign') }}
          <span>
            <RetryCampaignCreationOnPlatformButton
              :campaignId="campaign.id"
              @retry-successful="getCampaign"
              @retry-error="handleRetryError"
              color="white"
            />
          </span>
        </p>
        <p
          class="mb-0"
          v-if="campaign.status === 'FINISHED' || campaign.status === 'PAUSED'"
        >
          {{ $t('message_consolidate_metrics') }}
        </p>
      </div> <!-- END MESSAGES -->

      <!-- BIG NUMBERS -->
      <div class="mb-5">
        <h2 class="highlighted-color1 font-size-medium">
          <span>{{ $t('performance') }}</span>
        </h2>
        <!-- FILTER -->
        <div class="mb-4">
          <div class="d-flex text-center align-items-center justify-content-center justify-content-lg-end font-size-small default-form">
            {{ $t('filter_from') }}
            <DateTimeInput
              v-model.trim="filters.summaryStart"
              format="dd/MM/yyyy"
              type="date"
              theme="basic"
              class="mx-2 form-control-sm-wrapper"
            />
            {{ $t('to') }}
            <DateTimeInput
              v-model.trim="filters.summaryEnd"
              format="dd/MM/yyyy"
              type="date"
              theme="basic"
              class="mx-2 form-control-sm-wrapper"
            />
          </div>
        </div> <!-- END FILTER -->
        <div v-if="summaryLoader" class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
        <CampaignSummary
          :data="summary"
          :currency="campaign.advertiser.currency"
          v-else-if="summary"
        />
      </div> <!-- END BIG NUMBERS -->
      <!-- TABS -->
      <b-tabs content-class="mt-4" nav-class="default-tabs">
        <!-- OVERVIEW TAB -->
        <b-tab :title="$t('general_data')">
          <div class="mb-5">
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>{{ $t('general_data') }}</span>
            </h2>
            <div class="card">
              <div class="card-body">
                <p>
                  <strong>{{ $t('advertiser') }}:</strong> <router-link :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id } }" class="link-color3">{{ campaign.advertiser.name }}</router-link><br/>
                  <strong>{{ $t('status') }}:</strong> {{ campaign.status | formatCampaignStatus }}<br/>
                  <strong>{{ $t('start') }}:</strong> {{ campaign.start_date | formatDate }}<br/>
                  <strong>{{ $t('end') }}:</strong>
                  <span v-if="campaign.no_end_date"> {{ $t('no_end') }}</span>
                  <span v-else>{{ campaign.end_date | formatDate }}</span><br/>
                  <strong>{{ $t('category') }}:</strong> {{ $t('categories.' + campaign.category.slug) }}<br/>
                  <strong>{{ $t('daily_budget') }}:</strong> {{ campaign.amount / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
                  <strong>{{ $t('spending_to_date') }}:</strong> {{ campaign.spending / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
                  <strong>{{ $t('total_impressions') }}:</strong> {{ campaign.impressions | formatNumber }}<br/>
                  <strong>{{ $t('total_clicks') }}:</strong> {{ campaign.clicks | formatNumber }}<br/>
                  <strong>{{ $t('total_conversions') }}:</strong> {{ campaign.conversions | formatNumber }}<br/>
                  <strong>{{ $t('ctr') }}:</strong> {{ campaign.ctr | formatNumber }}%<br/>
                  <strong>{{ $t('cpm') }}:</strong> {{ campaign.cpm / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
                  <strong>{{ $t('cpa') }}:</strong> {{ campaign.cpa / 100 | formatMoney(campaign.advertiser.currency) }}
                </p>
              </div>
            </div>
          </div>
        </b-tab> <!-- END OVERVIEW TAB -->
        <!-- PACKAGES TAB -->
        <b-tab :title="$t('ad_groups')">
          <div>
            <!-- HEADER -->
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 class="highlighted-color1">
                  <span>{{ $t('ad_groups') }}</span>
                </h2>
              </div>
              <div>
                <router-link
                  :to="{ name: 'packages.create', params: { cId: campaign.id } }"
                  class="btn btn-color1"
                  v-if="(campaign.status === 'ACTIVE' || campaign.status === 'PAUSED') && !campaign.is_read_only"
                >
                  {{ $t('create_group') }}
                </router-link>
              </div>
            </div> <!-- END HEADER -->
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>{{ $t('name') }}</th>
                        <th>{{ $t('status') }}</th>
                        <th>{{ $t('budget') }}</th>
                        <th>{{ $t('spending') }}</th>
                        <th>{{ $t('impressions') }}</th>
                        <th>{{ $t('clicks') }}</th>
                        <th>{{ $t('conversions') }}</th>
                        <th>{{ $t('ctr') }}</th>
                        <th>{{ $t('cpm') }}</th>
                        <th>{{ $t('cpc') }}</th>
                        <th>{{ $t('cpa') }}</th>
                        <th>{{ $t('details') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(segmentation, index) in campaign.packages"
                        :key="index"
                        class="text-nowrap"
                      >
                        <td class="text-nowrap">
                          <router-link
                            :to="{ name: 'ad_groups.show', params: { cId: campaign.id, id: segmentation.id } }"
                            class="link-color3"
                          >
                            {{ segmentation.name }}
                          </router-link>
                        </td>
                        <td>{{ segmentation.status | formatCampaignStatus }}</td>
                        <td>{{ segmentation.amount / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                        <td>{{ segmentation.spending / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                        <td>{{ segmentation.impressions | formatNumber }}</td>
                        <td>{{ segmentation.clicks | formatNumber }}</td>
                        <td>{{ segmentation.conversions | formatNumber }}</td>
                        <td>{{ segmentation.ctr }}%</td>
                        <td>{{ segmentation.cpm / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                        <td>{{ segmentation.cpc / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                        <td>{{ segmentation.cpa / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                        <td>
                          <router-link
                            :to="{ name: 'ad_groups.show', params: { cId: campaign.id, id: segmentation.id } }"
                            class="link-color3"
                            v-b-tooltip.hover title="Ver mais"
                          >
                            <i class="fa fa-eye"></i>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </b-tab> <!-- END PACKAGES -->
        <!-- TRACKING TAB -->
        <b-tab title="Tracking" v-if="1 == 2">
          <div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>{{ $t('tracking') }}</span>
            </h2>
            <PixelRuleListTable
              :rules="campaign.pixels"
              :platform="campaign.platform"
              :advertiser="campaign.advertiser"
            />
          </div>
        </b-tab> <!-- END TRACKING -->
      </b-tabs> <!-- END TABS -->
    </div> <!-- END CAMPAIGN -->
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import variables from '@/mixins/variables'

import ActivateCampaignModal from '@/components/campaigns/ActivateCampaignModal'
import Button from '@/components/common/Button/Button'
import CampaignSummary from '@/components/campaigns/CampaignSummary'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import DestroyCampaignModal from '@/components/campaigns/DestroyCampaignModal'
import Loader from '@/components/common/Loader/Loader'
import PauseCampaignModal from '@/components/campaigns/PauseCampaignModal'
import PixelRuleListTable from '@/components/pixels/PixelRuleListTable'
import RetryCampaignCreationOnPlatformButton from '@/components/campaigns/RetryCampaignCreationOnPlatformButton'

export default {
  props: ['baseCampaign'],
  mixins: [variables],
  components: {
    ActivateCampaignModal,
    Button,
    CampaignSummary,
    DateTimeInput,
    DestroyCampaignModal,
    Loader,
    PauseCampaignModal,
    PixelRuleListTable,
    RetryCampaignCreationOnPlatformButton
  },
  data () {
    return {
      campaign: null,
      filters: {
        status: '',
        summaryStart: '',
        summaryEnd: ''
      },
      loader: false,
      summary: null,
      summaryLoader: true
    }
  },
  created () {
    this.campaign = this.baseCampaign
    this.filters.summaryStart = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(90, 'days').format()
    this.filters.summaryEnd = moment({ hour: 0, minute: 0, seconds: 0 }).format()
  },
  mounted () {
    this.getCampaignSummary()
  },
  watch: {
    'filters.summaryStart': function (newValue, oldValue) {
      if (!oldValue) return
      const newV = moment(newValue)
      const oldV = moment(oldValue)
      if (!newV.isSame(oldV)) {
        this.getCampaignSummary()
      }
    },
    'filters.summaryEnd': function (newValue, oldValue) {
      if (!oldValue) return
      const newV = moment(newValue)
      const oldV = moment(oldValue)
      if (!newV.isSame(oldV)) {
        this.getCampaignSummary()
      }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState('campaigns', {
      currentCampaign: state => state.currentCampaign
    }),
    /**
     * Check if has message to show user
     */
    hasMessage () {
      if (!this.campaign) return false
      return this.campaign.status === 'FINISHED' || this.campaign.status === 'PAUSED' || this.campaign.status === 'ERROR'
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Get advertiser
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.id)
        this.campaign = campaign
        this.setCurrentCampaign({ campaign: campaign })
        this.getCampaignSummary()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    },
    /**
     * Get campaign summary
     */
    async getCampaignSummary () {
      this.summaryLoader = true
      try {
        const summary = await CampaignsService.getCampaignsSummary({
          campaignIds: [this.campaign.id],
          startDate: moment(this.filters.summaryStart).format('YYYY-MM-DD'),
          endDate: moment(this.filters.summaryEnd).format('YYYY-MM-DD')
        })
        this.summary = summary
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.summaryLoader = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  width: 100%;
  word-wrap: break-word;
}
</style>
